'use strict';

var cov_1ms3e129i9 = function () {
  var path = '/Users/DW/Sites/aftercool/src/app/views/application/application.controller.js',
      hash = '379e77b0690e850914a4f25fb19aa240669e2ec6',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/Users/DW/Sites/aftercool/src/app/views/application/application.controller.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 46,
          column: 5
        }
      },
      '1': {
        start: {
          line: 4,
          column: 2
        },
        end: {
          line: 6,
          column: 52
        }
      },
      '2': {
        start: {
          line: 10,
          column: 17
        },
        end: {
          line: 10,
          column: 21
        }
      },
      '3': {
        start: {
          line: 12,
          column: 4
        },
        end: {
          line: 12,
          column: 25
        }
      },
      '4': {
        start: {
          line: 13,
          column: 4
        },
        end: {
          line: 13,
          column: 27
        }
      },
      '5': {
        start: {
          line: 16,
          column: 26
        },
        end: {
          line: 16,
          column: 28
        }
      },
      '6': {
        start: {
          line: 17,
          column: 6
        },
        end: {
          line: 19,
          column: 7
        }
      },
      '7': {
        start: {
          line: 18,
          column: 8
        },
        end: {
          line: 18,
          column: 15
        }
      },
      '8': {
        start: {
          line: 21,
          column: 6
        },
        end: {
          line: 27,
          column: 9
        }
      },
      '9': {
        start: {
          line: 22,
          column: 8
        },
        end: {
          line: 26,
          column: 9
        }
      },
      '10': {
        start: {
          line: 23,
          column: 24
        },
        end: {
          line: 23,
          column: 26
        }
      },
      '11': {
        start: {
          line: 24,
          column: 10
        },
        end: {
          line: 24,
          column: 49
        }
      },
      '12': {
        start: {
          line: 25,
          column: 10
        },
        end: {
          line: 25,
          column: 37
        }
      },
      '13': {
        start: {
          line: 29,
          column: 6
        },
        end: {
          line: 33,
          column: 9
        }
      },
      '14': {
        start: {
          line: 32,
          column: 8
        },
        end: {
          line: 32,
          column: 32
        }
      },
      '15': {
        start: {
          line: 38,
          column: 6
        },
        end: {
          line: 43,
          column: 11
        }
      },
      '16': {
        start: {
          line: 40,
          column: 10
        },
        end: {
          line: 40,
          column: 26
        }
      },
      '17': {
        start: {
          line: 42,
          column: 10
        },
        end: {
          line: 42,
          column: 21
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 1,
            column: 1
          },
          end: {
            line: 1,
            column: 2
          }
        },
        loc: {
          start: {
            line: 1,
            column: 13
          },
          end: {
            line: 46,
            column: 1
          }
        }
      },
      '1': {
        name: 'applicationCtrl',
        decl: {
          start: {
            line: 9,
            column: 11
          },
          end: {
            line: 9,
            column: 26
          }
        },
        loc: {
          start: {
            line: 9,
            column: 31
          },
          end: {
            line: 45,
            column: 3
          }
        }
      },
      '2': {
        name: 'submit',
        decl: {
          start: {
            line: 15,
            column: 13
          },
          end: {
            line: 15,
            column: 19
          }
        },
        loc: {
          start: {
            line: 15,
            column: 26
          },
          end: {
            line: 35,
            column: 5
          }
        }
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 21,
            column: 22
          },
          end: {
            line: 21,
            column: 23
          }
        },
        loc: {
          start: {
            line: 21,
            column: 43
          },
          end: {
            line: 27,
            column: 7
          }
        }
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 31,
            column: 14
          },
          end: {
            line: 31,
            column: 15
          }
        },
        loc: {
          start: {
            line: 31,
            column: 25
          },
          end: {
            line: 33,
            column: 7
          }
        }
      },
      '5': {
        name: 'sendEmail',
        decl: {
          start: {
            line: 37,
            column: 13
          },
          end: {
            line: 37,
            column: 22
          }
        },
        loc: {
          start: {
            line: 37,
            column: 36
          },
          end: {
            line: 44,
            column: 5
          }
        }
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 39,
            column: 14
          },
          end: {
            line: 39,
            column: 15
          }
        },
        loc: {
          start: {
            line: 39,
            column: 33
          },
          end: {
            line: 41,
            column: 9
          }
        }
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 41,
            column: 11
          },
          end: {
            line: 41,
            column: 12
          }
        },
        loc: {
          start: {
            line: 41,
            column: 25
          },
          end: {
            line: 43,
            column: 9
          }
        }
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 17,
            column: 6
          },
          end: {
            line: 19,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 17,
            column: 6
          },
          end: {
            line: 19,
            column: 7
          }
        }, {
          start: {
            line: 17,
            column: 6
          },
          end: {
            line: 19,
            column: 7
          }
        }]
      },
      '1': {
        loc: {
          start: {
            line: 22,
            column: 8
          },
          end: {
            line: 26,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 22,
            column: 8
          },
          end: {
            line: 26,
            column: 9
          }
        }, {
          start: {
            line: 22,
            column: 8
          },
          end: {
            line: 26,
            column: 9
          }
        }]
      },
      '2': {
        loc: {
          start: {
            line: 22,
            column: 12
          },
          end: {
            line: 22,
            column: 65
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 22,
            column: 12
          },
          end: {
            line: 22,
            column: 34
          }
        }, {
          start: {
            line: 22,
            column: 38
          },
          end: {
            line: 22,
            column: 65
          }
        }]
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

++cov_1ms3e129i9.s[0];
(function () {
  'use strict';

  ++cov_1ms3e129i9.f[0];
  ++cov_1ms3e129i9.s[1];
  angular.module('app').controller('applicationCtrl', applicationCtrl);

  /** @ngInject */
  function applicationCtrl($q) {
    ++cov_1ms3e129i9.f[1];

    var ctrl = (++cov_1ms3e129i9.s[2], this);

    ++cov_1ms3e129i9.s[3];
    ctrl.submit = submit;
    ++cov_1ms3e129i9.s[4];
    ctrl.emailSent = false;

    function submit(form) {
      ++cov_1ms3e129i9.f[2];

      var application = (++cov_1ms3e129i9.s[5], {});
      ++cov_1ms3e129i9.s[6];
      if (form.middleName) {
        ++cov_1ms3e129i9.b[0][0];
        ++cov_1ms3e129i9.s[7];

        return;
      } else {
        ++cov_1ms3e129i9.b[0][1];
      }

      ++cov_1ms3e129i9.s[8];
      _.mapKeys(form, function (value, key) {
        ++cov_1ms3e129i9.f[3];
        ++cov_1ms3e129i9.s[9];

        if ((++cov_1ms3e129i9.b[2][0], _.get(value, '$valid')) && (++cov_1ms3e129i9.b[2][1], _.get(value, '$modelValue'))) {
          ++cov_1ms3e129i9.b[1][0];

          var input = (++cov_1ms3e129i9.s[10], {});
          ++cov_1ms3e129i9.s[11];
          input[value.$name] = value.$modelValue;
          ++cov_1ms3e129i9.s[12];
          _.merge(application, input);
        } else {
          ++cov_1ms3e129i9.b[1][1];
        }
      });

      ++cov_1ms3e129i9.s[13];
      $q.all([sendEmail(application)]).then(function () {
        ++cov_1ms3e129i9.f[4];
        ++cov_1ms3e129i9.s[14];

        ctrl.emailSent = 'test';
      });
    }

    function sendEmail(application) {
      ++cov_1ms3e129i9.f[5];
      ++cov_1ms3e129i9.s[15];

      return emailjs.send("aftercooloutdoors@gmail.com", "application", application).then(function (response) {
        ++cov_1ms3e129i9.f[6];
        ++cov_1ms3e129i9.s[16];

        return response;
      }, function (err) {
        ++cov_1ms3e129i9.f[7];
        ++cov_1ms3e129i9.s[17];

        return err;
      });
    }
  }
})();