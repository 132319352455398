'use strict';

var cov_2k1suo9bwz = function () {
  var path = '/Users/DW/Sites/aftercool/src/app/views/contact-us/contact.controller.js',
      hash = 'f9b64f676565d3484867403865fcc3a433393fd3',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/Users/DW/Sites/aftercool/src/app/views/contact-us/contact.controller.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 50,
          column: 5
        }
      },
      '1': {
        start: {
          line: 4,
          column: 2
        },
        end: {
          line: 6,
          column: 44
        }
      },
      '2': {
        start: {
          line: 10,
          column: 17
        },
        end: {
          line: 10,
          column: 21
        }
      },
      '3': {
        start: {
          line: 12,
          column: 4
        },
        end: {
          line: 12,
          column: 25
        }
      },
      '4': {
        start: {
          line: 13,
          column: 4
        },
        end: {
          line: 13,
          column: 27
        }
      },
      '5': {
        start: {
          line: 16,
          column: 22
        },
        end: {
          line: 16,
          column: 24
        }
      },
      '6': {
        start: {
          line: 18,
          column: 6
        },
        end: {
          line: 20,
          column: 7
        }
      },
      '7': {
        start: {
          line: 19,
          column: 8
        },
        end: {
          line: 19,
          column: 15
        }
      },
      '8': {
        start: {
          line: 22,
          column: 6
        },
        end: {
          line: 28,
          column: 9
        }
      },
      '9': {
        start: {
          line: 23,
          column: 8
        },
        end: {
          line: 27,
          column: 9
        }
      },
      '10': {
        start: {
          line: 24,
          column: 24
        },
        end: {
          line: 24,
          column: 26
        }
      },
      '11': {
        start: {
          line: 25,
          column: 10
        },
        end: {
          line: 25,
          column: 49
        }
      },
      '12': {
        start: {
          line: 26,
          column: 10
        },
        end: {
          line: 26,
          column: 33
        }
      },
      '13': {
        start: {
          line: 30,
          column: 6
        },
        end: {
          line: 34,
          column: 9
        }
      },
      '14': {
        start: {
          line: 33,
          column: 8
        },
        end: {
          line: 33,
          column: 32
        }
      },
      '15': {
        start: {
          line: 39,
          column: 17
        },
        end: {
          line: 39,
          column: 36
        }
      },
      '16': {
        start: {
          line: 40,
          column: 19
        },
        end: {
          line: 40,
          column: 26
        }
      },
      '17': {
        start: {
          line: 41,
          column: 6
        },
        end: {
          line: 46,
          column: 11
        }
      },
      '18': {
        start: {
          line: 43,
          column: 10
        },
        end: {
          line: 43,
          column: 26
        }
      },
      '19': {
        start: {
          line: 45,
          column: 10
        },
        end: {
          line: 45,
          column: 21
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 1,
            column: 1
          },
          end: {
            line: 1,
            column: 2
          }
        },
        loc: {
          start: {
            line: 1,
            column: 12
          },
          end: {
            line: 50,
            column: 1
          }
        }
      },
      '1': {
        name: 'contactCtrl',
        decl: {
          start: {
            line: 9,
            column: 11
          },
          end: {
            line: 9,
            column: 22
          }
        },
        loc: {
          start: {
            line: 9,
            column: 27
          },
          end: {
            line: 49,
            column: 3
          }
        }
      },
      '2': {
        name: 'submit',
        decl: {
          start: {
            line: 15,
            column: 13
          },
          end: {
            line: 15,
            column: 19
          }
        },
        loc: {
          start: {
            line: 15,
            column: 26
          },
          end: {
            line: 36,
            column: 5
          }
        }
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 22,
            column: 22
          },
          end: {
            line: 22,
            column: 23
          }
        },
        loc: {
          start: {
            line: 22,
            column: 43
          },
          end: {
            line: 28,
            column: 7
          }
        }
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 32,
            column: 14
          },
          end: {
            line: 32,
            column: 15
          }
        },
        loc: {
          start: {
            line: 32,
            column: 25
          },
          end: {
            line: 34,
            column: 7
          }
        }
      },
      '5': {
        name: 'sendEmail',
        decl: {
          start: {
            line: 38,
            column: 13
          },
          end: {
            line: 38,
            column: 22
          }
        },
        loc: {
          start: {
            line: 38,
            column: 32
          },
          end: {
            line: 47,
            column: 5
          }
        }
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 42,
            column: 14
          },
          end: {
            line: 42,
            column: 15
          }
        },
        loc: {
          start: {
            line: 42,
            column: 33
          },
          end: {
            line: 44,
            column: 9
          }
        }
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 44,
            column: 11
          },
          end: {
            line: 44,
            column: 12
          }
        },
        loc: {
          start: {
            line: 44,
            column: 25
          },
          end: {
            line: 46,
            column: 9
          }
        }
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 18,
            column: 6
          },
          end: {
            line: 20,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 18,
            column: 6
          },
          end: {
            line: 20,
            column: 7
          }
        }, {
          start: {
            line: 18,
            column: 6
          },
          end: {
            line: 20,
            column: 7
          }
        }]
      },
      '1': {
        loc: {
          start: {
            line: 23,
            column: 8
          },
          end: {
            line: 27,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 23,
            column: 8
          },
          end: {
            line: 27,
            column: 9
          }
        }, {
          start: {
            line: 23,
            column: 8
          },
          end: {
            line: 27,
            column: 9
          }
        }]
      },
      '2': {
        loc: {
          start: {
            line: 23,
            column: 12
          },
          end: {
            line: 23,
            column: 65
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 23,
            column: 12
          },
          end: {
            line: 23,
            column: 34
          }
        }, {
          start: {
            line: 23,
            column: 38
          },
          end: {
            line: 23,
            column: 65
          }
        }]
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

++cov_2k1suo9bwz.s[0];
(function () {
  'use strict';

  ++cov_2k1suo9bwz.f[0];
  ++cov_2k1suo9bwz.s[1];
  angular.module('app').controller('contactCtrl', contactCtrl);

  /** @ngInject */
  function contactCtrl($q) {
    ++cov_2k1suo9bwz.f[1];

    var ctrl = (++cov_2k1suo9bwz.s[2], this);

    ++cov_2k1suo9bwz.s[3];
    ctrl.submit = submit;
    ++cov_2k1suo9bwz.s[4];
    ctrl.emailSent = false;

    function submit(form) {
      ++cov_2k1suo9bwz.f[2];

      var contact = (++cov_2k1suo9bwz.s[5], {});

      ++cov_2k1suo9bwz.s[6];
      if (form.middleName.$modelValue) {
        ++cov_2k1suo9bwz.b[0][0];
        ++cov_2k1suo9bwz.s[7];

        return;
      } else {
        ++cov_2k1suo9bwz.b[0][1];
      }

      ++cov_2k1suo9bwz.s[8];
      _.mapKeys(form, function (value, key) {
        ++cov_2k1suo9bwz.f[3];
        ++cov_2k1suo9bwz.s[9];

        if ((++cov_2k1suo9bwz.b[2][0], _.get(value, '$valid')) && (++cov_2k1suo9bwz.b[2][1], _.get(value, '$modelValue'))) {
          ++cov_2k1suo9bwz.b[1][0];

          var input = (++cov_2k1suo9bwz.s[10], {});
          ++cov_2k1suo9bwz.s[11];
          input[value.$name] = value.$modelValue;
          ++cov_2k1suo9bwz.s[12];
          _.merge(contact, input);
        } else {
          ++cov_2k1suo9bwz.b[1][1];
        }
      });

      ++cov_2k1suo9bwz.s[13];
      $q.all([sendEmail(contact)]).then(function () {
        ++cov_2k1suo9bwz.f[4];
        ++cov_2k1suo9bwz.s[14];

        ctrl.emailSent = 'test';
      });
    }

    function sendEmail(contact) {
      ++cov_2k1suo9bwz.f[5];

      var name = (++cov_2k1suo9bwz.s[15], 'aftercooloutdoors');
      var domain = (++cov_2k1suo9bwz.s[16], 'gmail');
      ++cov_2k1suo9bwz.s[17];
      return emailjs.send(name + '@' + domain + '.com', "contact_form", contact).then(function (response) {
        ++cov_2k1suo9bwz.f[6];
        ++cov_2k1suo9bwz.s[18];

        return response;
      }, function (err) {
        ++cov_2k1suo9bwz.f[7];
        ++cov_2k1suo9bwz.s[19];

        return err;
      });
    }
  }
})();