'use strict';

var cov_1aedctu1ds = function () {
  var path = '/Users/DW/Sites/aftercool/src/index.js',
      hash = '23bfcdb3f9d6bb0fd107df579c3a4ca09c715d66',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/Users/DW/Sites/aftercool/src/index.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 16,
          column: 8
        }
      },
      '1': {
        start: {
          line: 8,
          column: 4
        },
        end: {
          line: 8,
          column: 44
        }
      },
      '2': {
        start: {
          line: 9,
          column: 4
        },
        end: {
          line: 9,
          column: 43
        }
      },
      '3': {
        start: {
          line: 13,
          column: 6
        },
        end: {
          line: 13,
          column: 33
        }
      },
      '4': {
        start: {
          line: 14,
          column: 6
        },
        end: {
          line: 14,
          column: 45
        }
      },
      '5': {
        start: {
          line: 15,
          column: 6
        },
        end: {
          line: 15,
          column: 49
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 7,
            column: 10
          },
          end: {
            line: 7,
            column: 11
          }
        },
        loc: {
          start: {
            line: 7,
            column: 39
          },
          end: {
            line: 10,
            column: 3
          }
        }
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 12,
            column: 4
          },
          end: {
            line: 12,
            column: 5
          }
        },
        loc: {
          start: {
            line: 12,
            column: 42
          },
          end: {
            line: 16,
            column: 5
          }
        }
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0
    },
    f: {
      '0': 0,
      '1': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

++cov_1aedctu1ds.s[0];
angular.module('app', ['ui.router', 'angulartics', 'angulartics.google.analytics']).config(function ($analyticsProvider) {
  ++cov_1aedctu1ds.f[0];
  ++cov_1aedctu1ds.s[1];

  $analyticsProvider.firstPageview(false);
  ++cov_1aedctu1ds.s[2];
  $analyticsProvider.withAutoBase(false);
}).run(['$rootScope', '$state', '$stateParams', function ($rootScope, $state, $stateParams) {
  ++cov_1aedctu1ds.f[1];
  ++cov_1aedctu1ds.s[3];

  $rootScope.$state = $state;
  ++cov_1aedctu1ds.s[4];
  $rootScope.$stateParams = $stateParams;
  ++cov_1aedctu1ds.s[5];
  emailjs.init("user_zoyqNWTQyAbtlv6fxtzKH");
}]);