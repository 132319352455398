'use strict';

var cov_1rjl5nawf5 = function () {
  var path = '/Users/DW/Sites/aftercool/src/routes.js',
      hash = '1e61b14bb039492cbf4d7b8d679e1570bb1294df',
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/Users/DW/Sites/aftercool/src/routes.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 3,
          column: 24
        }
      },
      '1': {
        start: {
          line: 7,
          column: 2
        },
        end: {
          line: 7,
          column: 52
        }
      },
      '2': {
        start: {
          line: 8,
          column: 2
        },
        end: {
          line: 8,
          column: 36
        }
      },
      '3': {
        start: {
          line: 10,
          column: 2
        },
        end: {
          line: 158,
          column: 7
        }
      }
    },
    fnMap: {
      '0': {
        name: 'routesConfig',
        decl: {
          start: {
            line: 6,
            column: 9
          },
          end: {
            line: 6,
            column: 21
          }
        },
        loc: {
          start: {
            line: 6,
            column: 77
          },
          end: {
            line: 159,
            column: 1
          }
        }
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0
    },
    f: {
      '0': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

++cov_1rjl5nawf5.s[0];
angular.module('app').config(routesConfig);

/** @ngInject */
function routesConfig($stateProvider, $urlRouterProvider, $locationProvider) {
  ++cov_1rjl5nawf5.f[0];
  ++cov_1rjl5nawf5.s[1];

  $locationProvider.html5Mode(true).hashPrefix('!');
  ++cov_1rjl5nawf5.s[2];
  $urlRouterProvider.otherwise('/');

  ++cov_1rjl5nawf5.s[3];
  $stateProvider.state('home', {
    url: '/',
    templateUrl: 'app/views/home/main.html',
    data: {
      page: {
        title: 'After COOL'
      }
    }
  }).state('about', {
    url: '/about',
    templateUrl: 'app/views/about-us/about-us.html',
    data: {
      page: {
        title: 'History of After COOL'
      }
    }
  }).state('application', {
    url: '/application',
    controller: 'applicationCtrl',
    controllerAs: 'ctrl',
    templateUrl: 'app/views/application/application.html',
    data: {
      page: {
        title: 'Become a Coach Application - After COOL'
      }
    }
  }).state('adventure', {
    url: '/adventure',
    templateUrl: 'app/views/adventure/adventure.html',
    data: {
      page: {
        title: 'Adventure - After COOL'
      }
    }
  }).state('coaches', {
    url: '/coaches',
    templateUrl: 'app/views/coaches/coaches.html',
    data: {
      page: {
        title: 'Coaches at After COOL'
      }
    }
  }).state('contact', {
    url: '/contact',
    controller: 'contactCtrl',
    controllerAs: 'ctrl',
    templateUrl: 'app/views/contact-us/contact-us.html',
    data: {
      page: {
        title: 'Contact - After COOL'
      }
    }
  }).state('fitness', {
    url: '/fitness',
    templateUrl: 'app/views/fitness/fitness.html',
    data: {
      page: {
        title: 'Fitness - After COOL'
      }
    }
  }).state('team-building', {
    url: '/team-building',
    templateUrl: 'app/views/team-building/team-building.html',
    data: {
      page: {
        title: 'Team Building the Cool Way'
      }
    }
  }).state('outdoors', {
    url: '/outdoors',
    templateUrl: 'app/views/outdoors/outdoors.html',
    data: {
      page: {
        title: 'Outdoors - After COOL'
      }
    }
  }).state('article1', {
    url: '/article1',
    templateUrl: 'app/views/articles/creative-environments.html',
    data: {
      page: {
        title: 'Creating Creative Environments - After COOL'
      }
    }
  }).state('article2', {
    url: '/article2',
    templateUrl: 'app/views/articles/daydreaming-effect.html',
    data: {
      page: {
        title: 'Daydreaming effects - After COOL'
      }
    }
  }).state('article3', {
    url: '/article3',
    templateUrl: 'app/views/articles/motivated-to-exercise.html',
    data: {
      page: {
        title: 'How to Motivate exercise - After COOL'
      }
    }
  }).state('article4', {
    url: '/article4',
    templateUrl: 'app/views/articles/role-models.html',
    data: {
      page: {
        title: 'Role Models - After COOL'
      }
    }
  }).state('article5', {
    url: '/article5',
    templateUrl: 'app/views/articles/rough-housing.html',
    data: {
      page: {
        title: 'Rough Housing - After COOL'
      }
    }
  }).state('article6', {
    url: '/article6',
    templateUrl: 'app/views/articles/school-programs.html',
    data: {
      page: {
        title: 'School Programs  - After COOL'
      }
    }
  }).state('article7', {
    url: '/article7',
    templateUrl: 'app/views/articles/teach-money.html',
    data: {
      page: {
        title: 'Daydreaming effects - After COOL'
      }
    }
  });
}